import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React, { useEffect } from 'react';
import {Router} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import {useDispatch} from 'react-redux';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './configs/routesConfig';
import { getCurrentUser } from './auth/store/userApi';
import { submitLogin } from './auth/store/loginSlice';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName({ disableGlobal: true });

function App() {
	const dispatch = useDispatch();

	const tryToLoginWithTokens = async () => {
		const query = new URLSearchParams(history.location.search);
		const userData = {
			userId: query.get('userId'),
			key: query.get('key'),
			token: query.get('token')
		};
		if (userData.userId && userData.key && userData.token) {
			await dispatch(submitLogin(userData)).then(() => {
				history.push({pathname: history.location.pathname});
				dispatch(getCurrentUser());
			});
		} else {
			await dispatch(getCurrentUser());
		}
	};

	useEffect(() => {
		async function fetchData() {
		if (history.location && history.location.search) {
			await tryToLoginWithTokens();
		} else {
			dispatch(getCurrentUser());
		}}
		fetchData();
	}, [dispatch]);

	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Auth>
							<Router history={history}>
								<FuseAuthorization>
									<FuseTheme>
										<SnackbarProvider
											maxSnack={5}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right'
											}}
											classes={{
												containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
											}}
										>
											<FuseLayout />
										</SnackbarProvider>
									</FuseTheme>
								</FuseAuthorization>
							</Router>
						</Auth>
					</MuiPickersUtilsProvider>
			</StylesProvider>
		</AppContext.Provider>
	);
}

export default App;
